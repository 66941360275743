<script>
import User from "@/components/User.vue";

export default {
    name: "UvedListitem",
    components: {User},

    props: {
        item: {
            type: Object,
            required: true
        },

        columns: {
            type: Array,
            required: true
        },

        userBlock: {
            type: Boolean,
            required: true
        }
    },

    computed: {
        activeColumns() {
            return this.columns.filter(column => {
                return column.active
                    && column.prop !== 'id'
                    && column.prop !== 'admin'
                    && column.prop !== 'name'
                    && column.prop !== 'deal_status_id'
            });
        }
    },

    methods: {
        deleteItem(item) {
            this.$emit('deleteItem', item);
        }
    }
}
</script>

<template>
    <div class="item">
        <div class="item__header">
            <div class="item__status small-text" :style="{ backgroundColor: item.deal_status_color }">{{ item.deal_status }}</div>
            <div class="item__created_at text-color-light small-text">{{ item.created_at }}</div>
        </div>

        <div class="item__description">
            <div class="item__id text-color-light small-text">#{{ item.id }}</div>
            <div class="item__name">
                <router-link :disabled="userBlock" text plain :to="'/deal/' + item.uuid + '/show'" class="cursor-pointer font_weight_500 button_link">
                    {{ item.name }}
                </router-link>
                <v-icon @click="deleteItem(item)" :title="$t('delete')">
                    mdi-delete-outline mdi-18px
                </v-icon>
            </div>

            <div v-if="item.text" class="item__note text-color-light">{{ item.text }}</div>
        </div>

        <div class="item__data" v-if="activeColumns.length > 0">
            <div class="text-info" v-for="column in activeColumns" :key="column.name">
                <div class="text-info__label">
                    <div>{{ column.label }}</div>
                </div>
                <div class="text-info__value">
                    <slot :name="column.prop" :item="item">
                        <div>{{ item[column.prop] }}</div>
                    </slot>
                </div>
            </div>
        </div>

        <div class="item__footer">
            <User :item="item.admin" />
        </div>
    </div>
</template>
